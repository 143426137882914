.mobile-col {
  width: 150px;
  max-width: 150px;
}

.email-col {
  width: 220px;
  max-width: 220px;
}

.account-summary-table {
  border: 1px solid #ccc;

  tr {
    &:nth-child(even) {
      background-color: #eee;
    }
  }

  th {
    font-weight: normal;
    text-align: left;
  }

  td,
  th {
    padding: 7px 10px;
    font-size: 15px;
  }

  .balanceRow {
    td,
    th {
      font-weight: 500;
      font-size: 16px;
    }
  }
}

.addressBlock {
  max-width: 320px;
}

.order-customer-detials {
  font-size: 15px;

  .ant-table-cell {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .ant-table-row {
    td {
      &:first-child {
        color: #777;
      }
    }
  }
}
